<template>
  <div class="g-catalogue-footer__section" v-if="category.content">
    <div class="g-catalogue-footer__content">
      <div class="post-entry__category-content" v-html="category.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryContent",
  props: {
    category: {
      type: Object,
      required: true,
    },
  }
}
</script>